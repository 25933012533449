@import './fonts.css';
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55?styles=30125,16015,16019,16008,16021,15983,16014,15991,15993,15996,30131,30133');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: 'Helvetica Neue', sans-serif; */
}


a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0.5rem;

}


/* Hide horizontal scrollbar */ 
 ::-webkit-scrollbar-horizontal {
  display: none;
} 

/* Style the remaining vertical scrollbar track and thumb (optional) */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
} 